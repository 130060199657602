var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "1/2", pageTitle: _vm.pageTitle } },
    [
      _c("vx-card", [
        !_vm.readOnly
          ? _c(
              "div",
              { staticClass: "flex flex-wrap items-center mb-base" },
              [
                _c("vs-avatar", {
                  staticClass: "mr-4 mb-4 company-logo-avatar",
                  attrs: {
                    src: _vm.form.profilePicUrl,
                    icon: "cloud_upload",
                    size: "70px",
                    disabled: _vm.readOnly,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.profilePicInput.click()
                    },
                  },
                }),
                _c(
                  "div",
                  [
                    _c("input", {
                      ref: "profilePicInput",
                      attrs: {
                        type: "file",
                        hidden: "",
                        id: "profilePicInput",
                      },
                      on: { change: _vm.handleFileUpload },
                    }),
                    _c(
                      "vs-button",
                      {
                        staticClass: "mr-4 sm:mb-0 mb-2",
                        on: {
                          click: function ($event) {
                            return _vm.$refs.profilePicInput.click()
                          },
                        },
                      },
                      [_vm._v("Upload Profile Picture")]
                    ),
                    _c(
                      "vs-button",
                      {
                        attrs: { type: "border", color: "danger" },
                        on: { click: _vm.removeLogo },
                      },
                      [_vm._v("Remove")]
                    ),
                    _c("p", { staticClass: "text-sm mt-2" }, [
                      _vm._v("Allowed JPG, GIF or PNG. Max size of 800kB"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.readOnly
          ? _c("div", { staticClass: "flex flex-wrap items-center mb-base" }, [
              _c(
                "div",
                {
                  staticClass: "img-container",
                  staticStyle: { width: "70px" },
                },
                [
                  _c("img", {
                    staticClass: "rounded w-full",
                    attrs: { src: _vm.form.profilePicUrl },
                  }),
                ]
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "vx-row" }, [
          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
            _c(
              "div",
              { staticClass: "flex items-end" },
              [
                _c("feather-icon", {
                  staticClass: "mr-2",
                  attrs: { icon: "SettingsIcon", svgClasses: "w-5 h-5" },
                }),
                _c("span", { staticClass: "leading-none font-medium" }, [
                  _vm._v("General"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|email",
                      expression: "'required|email'",
                    },
                  ],
                  staticClass: "w-full mt-4",
                  attrs: {
                    label: "Email*",
                    name: "Email",
                    disabled: _vm.readOnly || _vm.editMode,
                  },
                  model: {
                    value: _vm.form.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "email", $$v)
                    },
                    expression: "form.email",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Email"))),
                ]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:50",
                      expression: "'max:50'",
                    },
                  ],
                  staticClass: "w-full mt-4",
                  attrs: {
                    label: "First Name",
                    name: "First Name",
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.form.firstName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "firstName", $$v)
                    },
                    expression: "form.firstName",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("First Name"))),
                ]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:50",
                      expression: "'max:50'",
                    },
                  ],
                  staticClass: "w-full mt-4",
                  attrs: {
                    label: "Last Name",
                    name: "Last Name",
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.form.lastName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "lastName", $$v)
                    },
                    expression: "form.lastName",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Last Name"))),
                ]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:50",
                      expression: "'max:50'",
                    },
                  ],
                  staticClass: "w-full mt-4 mb-4",
                  attrs: {
                    label: "Role Title",
                    name: "Role Title",
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.form.roleTitle,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "roleTitle", $$v)
                    },
                    expression: "form.roleTitle",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Role Title"))),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-4" },
                  [
                    _c("label", { staticClass: "text-sm" }, [_vm._v("Role*")]),
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        name: "Role",
                        options: _vm.roleOptions,
                        disabled: _vm.readOnly,
                      },
                      on: { input: _vm.setSelectedRole },
                      model: {
                        value: _vm.selectedRole,
                        callback: function ($$v) {
                          _vm.selectedRole = $$v
                        },
                        expression: "selectedRole",
                      },
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("Role"))),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
            _c(
              "div",
              { staticClass: "flex items-end md:mt-0 mt-base" },
              [
                _c("feather-icon", {
                  staticClass: "mr-2",
                  attrs: { icon: "UserIcon", svgClasses: "w-5 h-5" },
                }),
                _c("span", { staticClass: "leading-none font-medium" }, [
                  _vm._v("Personal Info"),
                ]),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "mt-4" },
                  [
                    _c("label", { staticClass: "text-sm" }, [
                      _vm._v("Date of Birth"),
                    ]),
                    _c("flat-pickr", {
                      staticClass: "w-full",
                      attrs: {
                        config: { dateFormat: "Y-m-d" },
                        name: "dob",
                        disabled: _vm.readOnly,
                      },
                      model: {
                        value: _vm.form.dob,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "dob", $$v)
                        },
                        expression: "form.dob",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-4" },
                  [
                    _c("label", { staticClass: "text-sm" }, [
                      _vm._v("Country"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        value: _vm.selectedCountry,
                        options: _vm.countryOptions,
                        disabled: _vm.readOnly,
                      },
                      on: { input: _vm.setSelectedCountry },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-4" },
                  [
                    _c("label", { staticClass: "text-sm" }, [
                      _vm._v("Spoken Languages"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        value: _vm.selectedLanguages,
                        multiple: "",
                        closeOnSelect: false,
                        options: _vm.langOptions,
                        disabled: _vm.readOnly,
                      },
                      on: { input: _vm.setSelectedLanguages },
                    }),
                  ],
                  1
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "max:20",
                      expression: "'max:20'",
                    },
                  ],
                  staticClass: "w-full mt-4",
                  attrs: {
                    label: "Phone",
                    type: "number",
                    name: "Phone",
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.form.phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "phone", $$v)
                    },
                    expression: "form.phone",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("Phone"),
                        expression: "errors.has('Phone')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("Phone")))]
                ),
                _c(
                  "div",
                  { staticClass: "mt-4" },
                  [
                    _c("label", { staticClass: "text-sm" }, [_vm._v("Title")]),
                    _c("v-select", {
                      attrs: {
                        value: _vm.selectedTitle,
                        options: _vm.titleOptions,
                        disabled: _vm.readOnly,
                      },
                      on: { input: _vm.setSelectedTitle },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-end" },
          [
            !_vm.readOnly
              ? _c(
                  "vs-button",
                  {
                    staticClass: "mt-4 mr-4",
                    attrs: { type: "border", color: "danger" },
                    on: { click: _vm.handleCancel },
                  },
                  [_vm._v("Cancel")]
                )
              : _vm._e(),
            !_vm.readOnly
              ? _c(
                  "vs-button",
                  { staticClass: "mt-4", on: { click: _vm.handleSubmit } },
                  [_vm._v("Save")]
                )
              : _vm._e(),
            _vm.readOnly
              ? _c(
                  "vs-button",
                  {
                    staticClass: "mt-4 mr-4",
                    on: { click: _vm.handleEditMode },
                  },
                  [_vm._v("Edit")]
                )
              : _vm._e(),
            _vm.readOnly
              ? _c(
                  "vs-button",
                  { staticClass: "mt-4", on: { click: _vm.handleCancel } },
                  [_vm._v("Back")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }